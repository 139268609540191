import { stripRichTextWrapperTag } from '~/utils/html';
import { sanitizeItems } from '../accordion-push/adapter';

export default async ({ primary }) => {
  const defaultModuleData = primary.accordion_push_default?.data;

  if (!defaultModuleData) return null;

  const defaultModuleProps = {
    title: stripRichTextWrapperTag(defaultModuleData?.accordion_push_title),
    isFAQ: defaultModuleData?.accordion_push_isfaq,
    items: defaultModuleData
      ? sanitizeItems(defaultModuleData.accordion_items)
      : [],
  };

  return defaultModuleProps;
};
